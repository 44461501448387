<template>
    <div class="video-container">
        <video controls v-if="type == 'vide' || type == 'video'">
            <source :src="video" type="video/mp4">
        </video>
        <div v-else>
            <a :href="video" target="_blank">Ver archivo</a>
        </div>
    </div>
</template>
<script>
export default {
    props: ['video', 'type']
}
</script>
<style scoped>
    .video-container{
        border-radius: 20px;
    }
    video {
        width: 100%;
        height: auto;
    }
</style>